@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;

.container {
  @include column($medium);

  padding: $medium;
  align-items: stretch;
  width: 100vw;
  height: 100vh;

  .content {
    @include row($medium);

    justify-content: center;
  }
}

.dark.container {
  background-color: $neutral;
}
