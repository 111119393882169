@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;
@use '@/styles/typography' as *;

.table {
  border-collapse: collapse;
  height: fit-content;

  th {
    @include bold-font($md);
  }

  th:first-child {
    border-top-left-radius: $extraSmall;
    border-bottom-left-radius: $extraSmall;
  }

  th:last-child {
    border-top-right-radius: $extraSmall;
    border-bottom-right-radius: $extraSmall;
  }

  th,
  td {
    padding: $small;
    text-align: center;
  }
}

.dark.table {
  background-color: $neutral;
  color: $neutral80;

  thead {
    background-color: $neutral40;
  }

  .row {
    border-bottom: 1px solid $neutral60;
  }
}
