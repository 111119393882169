@use '@/styles/spacing' as *;
@use '@/styles/grid' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;

.box {
  @include basic-rounded;
  @include column($small);

  width: 100%;
  height: 100%;
}

.video {
  @include column($small);

  position: relative;
  height: 100%;

  .bottomTag {
    position: absolute;
    bottom: $extraSmall;
    right: $extraSmall;
  }

  .actionsButton {
    top: $extraSmall;
    right: $extraSmall;
    position: absolute;
    z-index: 251;
    pointer-events: auto;

    button {
      padding: $extraSmall;
    }
  }
}

.primaryIcon {
  path {
    stroke: $primary60;
  }
}

.videoBox {
  @include basic-rounded;

  height: 100%;
  width: 100%;
}

.dark.box {
  .video {
    .bottomTag {
      div {
        background-color: $neutral60;
      }
    }
  }

  .videoBox {
    background-color: $neutral;
  }
}
