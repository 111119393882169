@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;

.container {
  @include row($medium);

  justify-content: space-between;
  width: 100%;
  padding-bottom: $medium;
}

.menuButton {
  background-color: transparent;
  padding: 0;
  border: none;
}

.userIcon {
  @include row($extraSmall);
  @include basic-rounded;

  align-items: center;
  padding: $extraSmall;
  background-color: $neutral40;

  svg {
    path {
      stroke: $success60;
    }
  }
}

.option {
  @include row($extraSmall);

  white-space: nowrap;
  padding: $small;
  align-items: center;
  justify-content: flex-start;
  background-color: $neutral;
  color: $neutral80;
  list-style: none;
  cursor: pointer;
}

.dark.container {
  border-bottom: 1px solid $neutral60;

  .option {
    background-color: $neutral;
    box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px,
      rgba(67, 90, 111, 0.47) 0px 8px 10px -4px;

    &:not(:last-child) {
      border-bottom: 1px solid $neutral60;
    }
  }

  .lockIcon {
    svg {
      path {
        stroke: $neutral80;
      }
    }
  }

  .chevronIcon {
    svg {
      path {
        stroke: $neutral80;
      }
    }
  }
}
