@use '@/styles/spacing' as *;
@use '@/styles/grid' as *;
@use '@/styles/colors' as *;

.container {
  @include col-centered();

  height: 100vh;
}

.wrapper {
  @include column($medium);
}

.dark.container {
  background-color: $neutral;
  color: $neutral80;
}
