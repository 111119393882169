@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;

.allParticipantsWrapper {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: $medium;
  overflow-y: auto;
}

.allParticipantsWrapper,
.mainScreen,
.sideParticipantsWrapper,
.participantsWrapper {
  max-height: calc(100vh - $medium * 2 - $small * 2 - 55.2px - 60px);
  height: 100%;
}

.participantsWrapper {
  @include row($medium);

  height: 33%;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.participantItem {
  width: 25%;
  height: 100%;
}

.sideParticipantsWrapper {
  @include column($medium);

  overflow-y: auto;
}

.listItem {
  width: 100%;
}
