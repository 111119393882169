@use '@/styles/spacing' as *;
@use '@/styles/grid' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;

.box {
  @include basic-rounded;

  border: none;
  padding: $extraSmall $small;
  cursor: pointer;
  transition: transform 0.1s;

  &:not(&:disabled):active {
    transform: scale(0.95);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.dark.box {
  background-color: $neutral;

  &:not(&:disabled):hover {
    background-color: $neutral60;
  }
}
