@use '@/styles/colors' as *;
@use '@/styles/containers' as *;
@use '@/styles/spacing' as *;

.menu {
  ul {
    @include basic-rounded;

    padding: 0;
  }
}

.menuButton {
  background-color: transparent;
  padding: 0;
  border: none;

  button {
    padding: $extraSmall;
  }
}

.menuItem {
  cursor: pointer;
  list-style: none;
  margin: 0 $small;
  padding: $small 0;
}

.primaryIcon {
  path {
    stroke: $primary60;
  }
}

.red {
  color: $error60;
}

.dark.menu {
  ul {
    background-color: $neutral40;

    .menuItem {
      background-color: $neutral40;

      &:not(:first-child) {
        border-top: 1px solid $neutral60;
      }
    }
  }
}
