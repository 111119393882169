@use '@/styles/grid' as *;
@use '@/styles/colors' as *;
@use '@/styles/spacing' as *;
@use '@/styles/typography' as *;

.button {
  @include regular-font($md);
  @include row($extraSmall);

  align-items: center;
  border: transparent 2px dashed;
  padding: $extraSmall $small;
  background-color: transparent;
  cursor: pointer;
}

.dark.button {
  color: $neutral80;

  &:hover {
    color: $primary60;
  }
}
