@use '@/styles/grid' as grid;
@use '@/styles/spacing' as spacing;
@use '@/styles/colors' as colors;

.box {
  @include grid.row(spacing.$medium);

  justify-content: center;
  padding: spacing.$medium;
  margin: 0 auto;
  max-width: 50rem;
  width: 80%;
  border-radius: spacing.$small;
  background-color: colors.$neutral;
}
