@use '@/styles/colors' as *;
@use '@/styles/typography' as *;
@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;

.container {
  @include column($medium);
}

.title {
  @include bold-font($xl);
}

.message {
  @include regular-font($lg);
}

.dark.container {
  .title {
    color: $neutral80;
  }
}

.dark.message {
  color: $neutral80;
}
