@use '@/styles/colors' as *;
@use '@/styles/spacing' as *;
@use '@/styles/containers' as *;
@use '@/styles/typography' as *;
@use '@/styles/grid' as *;

.container {
  @include column($extraSmall);

  align-items: flex-start;
  background-color: transparent;
  position: relative;
  width: 100%;
}

.label {
  @include semi-bold-font($sm);
}

.inputWrapper {
  width: 100%;
}

.input {
  @include basic-rounded;
  @include regular-font($md);

  width: 100%;
  padding: $small;
}

.icon {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.validationMessage {
  @include light-font($sm);

  color: $error60;
}
// THEME STYLES

.dark.container {
  color: $neutral80;

  .input,
  .input:-webkit-autofill {
    background-color: $neutral;
    border: 1px solid $neutral60;
    -webkit-box-shadow: 0 0 0 30px $neutral inset !important;
    -webkit-text-fill-color: $neutral80 !important;
    -webkit-background-clip: text;
    caret-color: $neutral80;

    &:active,
    &:focus {
      border: 1px solid $neutral60;
      -webkit-background-clip: text;
      -webkit-box-shadow: 0 0 0 30px $neutral inset !important;
      -webkit-text-fill-color: $neutral80 !important;
    }

    &::placeholder {
      color: $neutral80;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .label {
    color: $neutral80;
  }
}
