$primary: #0050f0;
$primary40: #0055ff;
$primary60: #3377ff;
$primary80: #6699ff;
$secondary: #33cc80;
$secondary40: #57db99;
$secondary60: #67e4a6;
$secondary80: #80ffbf;
$secondary100: #f1fdf7;
$black: #000000;
$softDark: #000002;
$neutral: #15181e;
$neutral40: #1f242e;
$neutral60: #303a50;
$neutral80: #e2e4e9;
$neutral90: #f4f6fa;
$white: #fff;
$success: #08450d;
$success40: #0f8a1a;
$success60: #75f080;
$success80: #baf7bf;
$success100: #e8fcea;
$alert: #4d1a00;
$alert40: #cc4400;
$alert60: #ff9966;
$alert80: #ffccb2;
$alert100: #ffeee5;
$error: #450812;
$error40: #8a0f24;
$error60: #f42547;
$error80: #f7bac4;
$error100: #fce8eb;
$red: #d14343;
