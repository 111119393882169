* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  overflow: hidden;
  background-color: #fff;
}

body {
  margin: 0;
}
