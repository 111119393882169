@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;

.sidesToolbar,
.middleToolbar {
  @include row($medium);
  @include basic-rounded;

  align-items: center;
  padding: $extraSmall;
  width: 100%;
}

.middleToolbar {
  justify-content: center;
}

.sidesToolbar {
  justify-content: space-between;
}

.dark.middleToolbar,
.dark.sidesToolbar {
  background-color: $neutral40;
  color: $neutral80;
}
