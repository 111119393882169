@use '~@/styles/colors' as colors;
@use '@/styles/grid' as grid;
@use '@/styles/spacing' as spacing;

.loading {
  pointer-events: none;

  :first-child {
    opacity: 0.75;
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.3s;
  z-index: 1;
}

.spinner {
  @include grid.column(0rem);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 250;

  circle {
    stroke: colors.$primary;
  }
}
.text {
  @include grid.padding-horizontal(spacing.$small);

  background-color: rgba(colors.$neutral80, 0.2);
  width: 100%;
  text-align: center;
}
