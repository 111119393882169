@use '~@/styles/colors' as colors;

.loader {
  border: 5px solid colors.$primary;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &-small {
    width: 2rem;
    height: 2rem;
  }

  &-medium {
    width: 3rem;
    height: 3rem;
  }

  &-large {
    width: 4rem;
    height: 4rem;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
