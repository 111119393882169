@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;
@use '@/styles/typography' as *;

.form {
  @include column($medium);

  width: 25rem;
  padding: $medium;
}

.title {
  @include bold-font($lg);

  text-align: center;
}

.inputsWrapper {
  @include column($small);
}

.dark.container {
  .form {
    @include basic-rounded;

    background-color: $neutral40;
  }
}
