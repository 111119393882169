@use '@/styles/spacing' as *;
@use '@/styles/grid' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;

.tag {
  @include basic-rounded;

  padding: $extraSmall;
}

.dark.tag {
  background-color: $neutral;
  color: $neutral80;
}
