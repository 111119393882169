@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;
@use '@/styles/typography' as *;
@use '@/styles/containers' as *;

.error {
  @include light-font($md);
  @include basic-rounded;

  background-color: $error80;
  color: $error40;
  padding: $small;
  text-align: center;
}
