@use '@/styles/containers' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;
@use '@/styles/typography' as *;

.button {
  @include basic-rounded;
  @include regular-font($md);

  padding: $extraSmall $small;
  cursor: pointer;
}

.dark.button {
  color: $neutral80;
  background-color: $neutral60;
  border: 1px solid $neutral60;

  &:hover {
    background-color: $primary60;
    border: 1px solid $primary60;
  }
}
