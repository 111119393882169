@use '@/styles/spacing' as *;
@use '@/styles/grid' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;

.videoView {
  @include column($small);

  justify-content: space-between;
  height: 100vh;

  padding: $medium;
}

.container {
  width: 100vw;
  max-height: 100vh;
}

.loader {
  display: block;
  margin: $medium auto;
}

.toolbarRight {
  @include row($small);

  align-items: center;
}

.menuMessage {
  margin: 0 $small;
  padding: $small 0;
}

.infoIcon {
  svg {
    path {
      stroke: $neutral;
    }
  }
}

.url {
  @include row(0.25rem);

  cursor: pointer;

  svg {
    path {
      stroke: $neutral80;
    }
  }

  p {
    margin: 0;
    max-width: 12rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.secondaryBox {
  margin: $medium auto;
  max-width: 30rem;
}

.ipCameraBox {
  max-width: 30rem;
}

.cookies {
  @include row-centered;

  height: 100vh;

  b {
    font-size: 1.5rem;
  }
}

.dark.container {
  background-color: $neutral;
  color: $neutral80;
}
