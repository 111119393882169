$fontFamily: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

$xs: 0.6rem;
$sm: 0.8rem;
$md: 1rem;
$lg: 1.5rem;
$xl: 2rem;
$xxl: 3rem;

@mixin light-font($font-size) {
  font-family: $fontFamily;
  font-size: $font-size;
  font-weight: 200;
}

@mixin regular-font($font-size) {
  font-family: $fontFamily;
  font-size: $font-size;
  font-weight: 400;
}

@mixin semi-bold-font($font-size) {
  font-family: $fontFamily;
  font-size: $font-size;
  font-weight: 500;
}

@mixin bold-font($font-size) {
  font-family: $fontFamily;
  font-size: $font-size;
  font-weight: 700;
}
