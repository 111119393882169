@use '@/styles/spacing' as spacing;
@use '@/styles/grid' as grid;
@use '@/styles/colors' as colors;

.selectBox {
  @include grid.column(spacing.$small);

  width: 100%;
}

.select {
  background-color: colors.$neutral80;
  padding: spacing.$small;
  border: 0.5px solid rgba(colors.$neutral, 0.3);
  border-radius: 4px;

  &:focus {
    box-shadow: 0px 0px 2px colors.$primary;
  }

  &:invalid {
    border: 0.5px solid colors.$error40;
  }
}

.selectInvalid {
  border: 0.5px solid colors.$error40;
}
