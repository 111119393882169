@use '@/styles/spacing' as *;
@use '@/styles/grid' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;

.menu {
  ul {
    @include basic-rounded;

    padding: 0;
  }
}

.menuButton {
  background-color: transparent;
  padding: 0;
  border: none;

  button {
    padding: $extraSmall;
  }
}

.menuItem {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: $small;
  cursor: pointer;
  list-style: none;
  margin: 0 $small;
  padding: $small 0;
  align-items: center;

  div {
    @include row($extraSmall);
  }

  button {
    padding: calc($extraSmall / 2) $extraSmall;
  }
}

.dark.menu {
  ul {
    background-color: $neutral40;

    .menuItem {
      background-color: $neutral40;

      &:not(:nth-child(2)) {
        border-top: 1px solid $neutral60;
      }
    }
  }
}
