@mixin padding-vertical($padding) {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin padding-horizontal($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin margin-vertical($margin) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin margin-horizontal($margin) {
  margin-left: $margin;
  margin-right: $margin;
}

@mixin column($spacing) {
  display: flex;
  flex-direction: column;
  gap: $spacing;
}

@mixin row($spacing) {
  display: flex;
  gap: $spacing;
}

@mixin row-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin col-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin full-space {
  width: 100%;
  height: 100%;
}
