@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;
@use '@/styles/typography' as *;
@use '@/styles/colors' as *;

.container {
  @include column($medium);
}

.filters {
  @include row($small);
}

.message {
  @include regular-font($lg);
}

.dark.container {
  .message {
    color: $neutral80;
  }
}
