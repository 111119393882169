@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;

.toasterWrapper {
  @include column($small);

  z-index: 101;
  position: absolute;
  left: 50%;
  top: $small;
  transform: translateX(-50%);
}

.toaster {
  animation: show 3s ease-in-out forwards;
}

@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  10% {
    display: initial;
    opacity: 100%;
  }
  80% {
    opacity: 100%;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
