@use '@/styles/grid' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;

.toolbar {
  @include row($small);
}

.menu {
  z-index: 251;

  ul {
    @include basic-rounded;

    padding: 0;
  }
}

.menuButton {
  background-color: transparent;
  padding: 0;
  border: none;
}

.menuItem {
  @include basic-rounded;

  list-style: none;
  padding: $small;
}

.primaryIcon {
  path {
    stroke: $primary60;
  }
}

.redIcon {
  path {
    stroke: $error60;
  }
}

.dark.toolbar {
  .menu {
    ul {
      background-color: $neutral40;

      .menuItem {
        background-color: $neutral40;

        &:not(:first-child) {
          border-top: 1px solid $neutral60;
        }
      }
    }
  }
}
