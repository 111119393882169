@use '@/styles/containers' as *;
@use '@/styles/spacing' as *;
@use '@/styles/colors' as *;
@use '@/styles/typography' as *;

.button {
  @include basic-rounded;
  @include regular-font($md);

  padding: $small $medium;
  cursor: pointer;
}

.dark.button {
  color: $neutral80;
  background-color: $primary;
  border: 1px solid $primary;

  &:hover {
    background-color: $primary60;
  }
}
