@use '@/styles/spacing' as *;
@use '@/styles/grid' as *;
@use '@/styles/colors' as *;
@use '@/styles/containers' as *;
@use '@/styles/typography' as *;

.box {
  @include basic-rounded;
  @include column($small);

  width: 100%;
  height: 100%;
}

.noVideo {
  @include row(0);
  @include semi-bold-font($lg);

  justify-content: center;
  align-items: center;
}

.video {
  @include column($small);

  position: relative;
  height: 100%;
  z-index: 2;

  .bottomTag {
    position: absolute;
    bottom: $extraSmall;
    right: $extraSmall;
  }

  .topTag {
    position: absolute;
    top: $extraSmall;
    left: $extraSmall;
    z-index: 251;
  }

  .actionsButton {
    top: $extraSmall;
    right: $extraSmall;
    position: absolute;
    z-index: 251;
    pointer-events: auto;
  }
}

.primaryIcon {
  path {
    stroke: $primary60;
  }
}

.dark.box {
  .video {
    .bottomTag {
      div {
        background-color: $neutral60;
      }
    }
  }

  .videoActive {
    background-color: $black;
  }

  .noVideo {
    background-color: $black;
    color: $neutral80;
  }

  .active {
    -webkit-box-shadow: inset 0px 0px $small 0px $primary60;
    -moz-box-shadow: inset 0px 0px $small 0px $primary60;
    box-shadow: inset 0px 0px $small 0px $primary60;
  }
}
