@use '@/styles/colors' as *;
@use '@/styles/spacing' as *;
@use '@/styles/grid' as *;
@use '@/styles/containers' as *;

.toaster {
  @include row($extraSmall);
  @include basic-rounded;

  padding: $small;

  p {
    margin: 0;
  }
}

.success {
  background-color: $success60;
  color: $success;
}

.warning {
  background-color: $alert80;
  color: $alert40;
}

.error {
  background-color: $error80;
  color: $error40;
}

.info {
  background-color: $neutral80;
  color: $neutral;
}
