@use '@/styles/colors' as *;
@use '@/styles/typography' as *;

.version {
  @include regular-font($md);

  text-align: center;
}

.dark.version {
  color: $neutral80;
}
